<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <div class="grid">
        <div class="col-12 md:col-12">
          <div class="card conteudo">
            <form @submit.prevent="validate()">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <label for="nome" class="required">Nome</label>
                  <InputText
                    id="nome"
                    v-model="rubrica.nome"
                    type="text"
                    :class="{
                      'p-invalid': submitted && v$.rubrica.nome.$invalid,
                    }" />
                  <div
                    v-if="submitted && v$.rubrica.nome.required.$invalid"
                    class="p-error">
                    O campo Nome é obrigatório.
                  </div>
                </div>
                <div class="field col-12 md:col-6">
                  <label for="rubrica" class="required">Rubrica</label>
                  <InputNumber
                    id="rubrica"
                    v-model="rubrica.rubrica"
                    :class="{
                      'p-invalid': submitted && v$.rubrica.rubrica.$invalid,
                    }"
                    :disabled="isEdit" />
                  <div
                    v-if="submitted && v$.rubrica.rubrica.required.$invalid"
                    class="p-error">
                    O campo Rubrica é obrigatório.
                  </div>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="field col-6 md:col-6">
                  <label for="atividade" class="required">Atividade</label>
                  <Dropdown
                    id="atividade"
                    v-model="rubrica.atividade"
                    :options="atividadeItems"
                    :filter="true"
                    :class="{
                      'p-invalid': submitted && v$.rubrica.atividade.$invalid,
                    }"
                    optionLabel="nome"
                    placeholder="Selecione"
                    @change="alterarListagemMargem()">
                  </Dropdown>
                  <div
                    v-if="submitted && v$.rubrica.atividade.required.$invalid"
                    class="p-error">
                    O campo Atividade é obrigatório.
                  </div>
                </div>
                <div class="field col-6 md:col-6">
                  <label for="entidade" class="required">Entidade</label>
                  <Dropdown
                    id="entidade"
                    v-model="rubrica.entidade"
                    :filter="true"
                    :class="{
                      'p-invalid': submitted && v$.rubrica.entidade.$invalid,
                    }"
                    :options="entidadeItems"
                    optionLabel="nome"
                    :disabled="isEdit"
                    :placeholder="exibirEntidadeSelecionada()">
                  </Dropdown>
                  <div
                    v-if="submitted && v$.rubrica.entidade.required.$invalid"
                    class="p-error">
                    O campo Entidade é obrigatório.
                  </div>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div
                  v-if="consignataria.id == null"
                  class="field col-6 md:col-6">
                  <label for="consignataria">Consignatária</label>
                  <Dropdown
                    id="consignataria"
                    v-model="rubrica.consignataria"
                    data-key="id"
                    filter="true"
                    :options="consignatariaItems"
                    optionLabel="razaoSocial"
                    placeholder="Selecione">
                    <template #option="slotProps">
                      <div>
                        <span>
                          {{ slotProps.option.cnpj }} -
                          {{ slotProps.option.razaoSocial }}
                        </span>
                      </div>
                    </template>
                  </Dropdown>
                  <div
                    v-if="
                      submitted && v$.rubrica.consignataria.required.$invalid
                    "
                    class="p-error">
                    O campo Consignatária é obrigatório.
                  </div>
                </div>
                <div class="field col-6 md:col-6">
                  <label for="entidade">Margem</label>
                  <Dropdown
                    id="colunaMargem"
                    v-model="rubrica.colunaMargem"
                    :options="margens"
                    :filter="true"
                    optionLabel="valor"
                    optionValue="chave"
                    :placeholder="exibirColunaMargemSelecionada()">
                  </Dropdown>
                  <div
                    v-if="
                      submitted && v$.rubrica.colunaMargem.required.$invalid
                    "
                    class="p-error">
                    O campo Coluna Margem é obrigatório.
                  </div>
                </div>
              </div>
              <div v-if="id">
                <div>Status</div>
                <div>
                  <InputSwitch v-model="rubrica.ativo" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Rubrica from '@/domain/Rubrica.js'
import RubricaService from '@/service/RubricaService.js'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import AtividadeService from '@/service/AtividadeService.js'
import EntidadeService from '@/service/EntidadeService.js'
import ConfiguracaoService from '@/service/ConfiguracaoService.js'
import UsuarioService from '@/service/UsuarioService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsrubrica: {
      type: Object,
      required: false,
      default: new Rubrica(),
    },
  },
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      rubrica: new Rubrica(),
      id: this.$route.params.id,
      consignataria: {
        id: this.$route.params.idConsignataria,
      },
      isEdit: false,
      submitted: false,
      saving: false,
      confirmDialog: false,
      atividadeItems: [{}],
      entidadeItems: [{}],
      consignatariaItems: [{}],
      margens: [
        {
          chave: '',
          valor: '',
          descricao: '',
        },
      ],
      todasAsMargens: [
        {
          chave: '',
          valor: '',
          descricao: '',
        },
      ],
      margemSelecionada: '',
    }
  },

  validations() {
    return {
      rubrica: {
        nome: { required },
        rubrica: { required },
        atividade: { required },
        entidade: { required },
        consignataria: { required },
        colunaMargem: { required },
      },
    }
  },

  created() {
    this.service = new RubricaService(this.$http)
    this.atividadeService = new AtividadeService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.configuracaoService = new ConfiguracaoService(this.$http)
    this.usuarioService = new UsuarioService(this.$http)
  },

  mounted() {
    if (this.id) {
      this.carregarRubrica()
    }
    this.carregarAtividade()
    this.carregarEntidade()
    this.carregarConsignataria()
    this.carregarMargens()
    if (this.id) this.isEdit = true
    else this.isEdit = false
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Rubrica' : 'Nova Rubrica'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.rubrica = JSON.parse(JSON.stringify(this.propsrubrica))
      this.id = this.rubrica?.id
      if (!this.id) {
        this.rubrica.ativo = true
      }
    },

    onHide() {
      this.submitted = false
    },
    carregarRubrica() {
      this.service.getRubrica(this.id).then(
        (res) => {
          this.rubrica = res
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    carregarAtividade() {
      this.atividadeService.getListaAtividades().then((res) => {
        this.atividadeItems = res
      })
    },

    carregarEntidade() {
      if (this.$auth.hasRoleGestaoEntidade()) {
        this.usuarioService
          .getListaEntidadesPorUsuarioGestaoEntidadeLogada()
          .then((res) => {
            this.entidadeItems = res
          })
      } else {
        this.entidadeService.getListaEntidades().then((res) => {
          this.entidadeItems = res
        })
      }
    },

    exibirEntidadeSelecionada() {
      if (this.rubrica.entidade != null && this.rubrica.entidade != '') {
        for (var i = 0; i < this.entidadeItems.length; i++) {
          if (this.entidadeItems[i].id === this.rubrica.entidade.id) {
            return this.entidadeItems[i].nome
          }
        }
      } else {
        return 'Selecione'
      }
    },

    carregarConsignataria() {
      if (this.consignataria.id != null) {
        this.consignatariaService
          .getConsignataria(this.consignataria.id)
          .then((res) => {
            this.consignatariaItems = res
          })
      } else {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatariaItems = res
        })
      }
    },

    carregarMargens() {
      this.configuracaoService.getMargens().then((res) => {
        this.margens = res
        this.todasAsMargens = res
      })
    },

    alterarListagemMargem() {
      if (
        this.rubrica.atividade.nome === 'ADIANTAMENTO' ||
        this.rubrica.atividade.nome.includes('ADIANTAMENTO')
      ) {
        this.margens = this.margens.filter(
          (margem) => margem.chave === 'margem5',
        )
      } else if (
        this.rubrica.atividade.nome === 'CARTAO DE CREDITO' ||
        this.rubrica.atividade.nome.includes('CARTAO DE CREDITO')
      ) {
        this.margens = this.margens.filter(
          (margem) => margem.chave === 'margem4',
        )
      } else if (
        this.rubrica.atividade.nome === 'EMPRESTIMO' ||
        this.rubrica.atividade.nome.includes('EMPRESTIMO')
      ) {
        this.margens = this.margens.filter(
          (margem) => margem.chave === 'margem3' || margem.chave === 'margem6',
        )
      } else {
        this.margens = this.todasAsMargens
      }
    },

    validate() {
      if (this.consignataria.id) {
        this.rubrica.consignataria = this.consignataria
      }
      this.submitted = true
      this.v$.rubrica.$touch()

      if (this.v$.rubrica.$invalid) {
        return
      } else {
        this.extrairValorColunaMargem()
        this.salvar()
      }
    },

    extrairValorColunaMargem() {
      if (typeof this.rubrica.colunaMargem !== 'number') {
        this.rubrica.colunaMargem = this.rubrica.colunaMargem.replace(
          /[^0-9]/g,
          '',
        )
      }
    },

    exibirColunaMargemSelecionada() {
      if (
        this.rubrica.colunaMargem != null &&
        this.rubrica.colunaMargem != ''
      ) {
        for (var i = 0; i < this.margens.length; i++) {
          if (
            this.margens[i].chave.replace(/[^0-9]/g, '') ==
            this.rubrica.colunaMargem
          ) {
            return this.margens[i].valor
          }
        }
      } else {
        return 'Selecione'
      }
    },

    salvar() {
      this.saving = true
      this.service
        .saveRubrica(this.rubrica)
        .then(
          () => {
            if (this.id) {
              this.exibeToast('update')
            } else {
              this.exibeToast('save')
            }

            if (this.consignataria?.id) {
              this.$router.push({
                name: 'consignataria-rubricas',
              })
            }
          },
          (err) => {
            this.exibeToast('error', err.response.data.message)
          },
        )
        .finally(() => {
          this.saving = false
          this.submitted = false
          this.$emit('carregarDados')
          this.fechar()
        })
    },

    exibeToast(tipo, msg) {
      if (tipo === 'save') {
        this.$toast.add({
          severity: 'success',
          summary: 'Rubrica salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'update') {
        this.$toast.add({
          severity: 'success',
          summary: 'Rubrica Atualizada com sucesso!',
          life: 10000,
        })
      }
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}

.conteudo {
  height: 380px;
}
</style>
