var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"header":_vm.exibirHeader(),"closeOnEscape":!_vm.saving && !_vm.confirmDialog,"closable":!_vm.saving,"modal":true,"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"hide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fechar()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"disabled":_vm.saving,"label":"Cancelar","icon":"pi pi-times"},on:{"click":function($event){return _vm.fechar()}}}),_c('Button',{attrs:{"disabled":_vm.saving,"autofocus":""},on:{"click":_vm.validate}},[(!_vm.saving)?_c('span',{staticClass:"pi pi-check"}):_vm._e(),(_vm.saving)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),(!_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Salvar")]):_vm._e(),(_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")]):_vm._e()])]},proxy:true}])},[_c('BlockUI',{attrs:{"blocked":_vm.saving}},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12 md:col-12"},[_c('div',{staticClass:"card conteudo"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"nome"}},[_vm._v("Nome")]),_c('InputText',{class:{
                    'p-invalid': _vm.submitted && _vm.v$.rubrica.nome.$invalid,
                  },attrs:{"id":"nome","type":"text"},model:{value:(_vm.rubrica.nome),callback:function ($$v) {_vm.$set(_vm.rubrica, "nome", $$v)},expression:"rubrica.nome"}}),(_vm.submitted && _vm.v$.rubrica.nome.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Nome é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-12 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"rubrica"}},[_vm._v("Rubrica")]),_c('InputNumber',{class:{
                    'p-invalid': _vm.submitted && _vm.v$.rubrica.rubrica.$invalid,
                  },attrs:{"id":"rubrica","disabled":_vm.isEdit},model:{value:(_vm.rubrica.rubrica),callback:function ($$v) {_vm.$set(_vm.rubrica, "rubrica", $$v)},expression:"rubrica.rubrica"}}),(_vm.submitted && _vm.v$.rubrica.rubrica.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Rubrica é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"atividade"}},[_vm._v("Atividade")]),_c('Dropdown',{class:{
                    'p-invalid': _vm.submitted && _vm.v$.rubrica.atividade.$invalid,
                  },attrs:{"id":"atividade","options":_vm.atividadeItems,"filter":true,"optionLabel":"nome","placeholder":"Selecione"},on:{"change":function($event){return _vm.alterarListagemMargem()}},model:{value:(_vm.rubrica.atividade),callback:function ($$v) {_vm.$set(_vm.rubrica, "atividade", $$v)},expression:"rubrica.atividade"}}),(_vm.submitted && _vm.v$.rubrica.atividade.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Atividade é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"entidade"}},[_vm._v("Entidade")]),_c('Dropdown',{class:{
                    'p-invalid': _vm.submitted && _vm.v$.rubrica.entidade.$invalid,
                  },attrs:{"id":"entidade","filter":true,"options":_vm.entidadeItems,"optionLabel":"nome","disabled":_vm.isEdit,"placeholder":_vm.exibirEntidadeSelecionada()},model:{value:(_vm.rubrica.entidade),callback:function ($$v) {_vm.$set(_vm.rubrica, "entidade", $$v)},expression:"rubrica.entidade"}}),(_vm.submitted && _vm.v$.rubrica.entidade.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Entidade é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[(_vm.consignataria.id == null)?_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"consignataria"}},[_vm._v("Consignatária")]),_c('Dropdown',{attrs:{"id":"consignataria","data-key":"id","filter":"true","options":_vm.consignatariaItems,"optionLabel":"razaoSocial","placeholder":"Selecione"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.option.cnpj)+" - "+_vm._s(slotProps.option.razaoSocial)+" ")])])]}}],null,false,863649961),model:{value:(_vm.rubrica.consignataria),callback:function ($$v) {_vm.$set(_vm.rubrica, "consignataria", $$v)},expression:"rubrica.consignataria"}}),(
                    _vm.submitted && _vm.v$.rubrica.consignataria.required.$invalid
                  )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Consignatária é obrigatório. ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"entidade"}},[_vm._v("Margem")]),_c('Dropdown',{attrs:{"id":"colunaMargem","options":_vm.margens,"filter":true,"optionLabel":"valor","optionValue":"chave","placeholder":_vm.exibirColunaMargemSelecionada()},model:{value:(_vm.rubrica.colunaMargem),callback:function ($$v) {_vm.$set(_vm.rubrica, "colunaMargem", $$v)},expression:"rubrica.colunaMargem"}}),(
                    _vm.submitted && _vm.v$.rubrica.colunaMargem.required.$invalid
                  )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Coluna Margem é obrigatório. ")]):_vm._e()],1)]),(_vm.id)?_c('div',[_c('div',[_vm._v("Status")]),_c('div',[_c('InputSwitch',{model:{value:(_vm.rubrica.ativo),callback:function ($$v) {_vm.$set(_vm.rubrica, "ativo", $$v)},expression:"rubrica.ativo"}})],1)]):_vm._e()])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }