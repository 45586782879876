export default class ConfiguracaoService {
  constructor(http) {
    this._http = http
  }

  async getListaConfiguracoes() {
    const { data } = await this._http.get(`/api/configuracoes/list`)
    return data
  }

  async getConfiguracoes(dados) {
    const { data } = await this._http.get(
      `/api/configuracoes/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async saveConfiguracao(configuracao) {
    if (configuracao.id) {
      return await this._http.put('/api/configuracoes', configuracao)
    } else {
      return await this._http.post('/api/configuracoes', configuracao)
    }
  }

  async deleteConfiguracao(chave) {
    await this._http.delete(`/api/configuracoes/${chave}`)
  }

  async getConfiguracao(id) {
    const { data } = await this._http.get(`/api/configuracoes/${id}`)
    return data
  }
  async getMargens() {
    const { data } = await this._http.get('/api/configuracoes/margens')
    return data
  }
}
