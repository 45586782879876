export default class AtividadeService {
  constructor(http) {
    this._http = http
  }

  async getListaAtividades() {
    const { data } = await this._http.get(`/api/atividades/list`)
    return data
  }

  async getAtividades(dados) {
    const { data } = await this._http.get(
      `/api/atividades/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async saveAtividade(atividade) {
    if (atividade.id) {
      return await this._http.put('/api/atividades', atividade)
    } else {
      return await this._http.post('/api/atividades', atividade)
    }
  }

  async deleteAtividade(atividade) {
    await this._http.delete(`/api/atividades/${atividade.id}`)
  }

  async getAtividade(id) {
    const { data } = await this._http.get(`/api/atividades/${id}`)
    return data
  }
}
