export default class Rubrica {
  constructor(
    id = null,
    nome = '',
    rubrica = null,
    atividade = null,
    consignataria = null,
    entidade = null,
    alteradoPor = null,
    cadastradoPor = null,
    colunaMargem = null,
  ) {
    this.id = id
    this.nome = nome
    this.rubrica = rubrica
    this.atividade = atividade
    this.consignataria = consignataria
    this.entidade = entidade
    this.alteradoPor = alteradoPor
    this.cadastradoPor = cadastradoPor
    this.ativo = true
    this.colunaMargem = colunaMargem
  }
}
