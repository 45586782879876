export default class RubricaService {
  constructor(http) {
    this._http = http
  }

  async getListaRubricas() {
    const { data } = await this._http.get('/api/rubricas/list')
    return data
  }

  async getListaRubricasPorEntidade(matriculaId) {
    const { data } = await this._http.get(
      `/api/rubricas/matriculaId/${matriculaId}`,
    )
    return data
  }

  async getListaRubricasUsuarioGestaoEntidade() {
    const { data } = await this._http.get('/api/rubricas/usuarioGestaoEntidade')
    return data
  }

  async getListaRubricasUsuarioGestaoEntidadeEConsignatariaId(consignatariaId) {
    const { data } = await this._http.get(
      `/api/rubricas/usuarioGestaoEntidade/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaRubricasAtivasPorAtividade(atividade, matriculaId) {
    const { data } = await this._http.get(
      `/api/rubricas/atividade/${atividade}/matricula/${matriculaId}`,
    )
    return data
  }

  async getListaRubricasAtivasPorAtividades(
    atividades,
    matriculaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/rubricas/atividades/${atividades}/matricula/${matriculaId}/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaRubricasAtivasPorAtividadesDesconsiderarRubrica(
    atividades,
    matriculaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/rubricas/atividades/${atividades}/matricula/${matriculaId}/consignataria/${consignatariaId}/desconsiderar-rubrica`,
    )
    return data
  }

  async getRubricas(dados) {
    const { data } = await this._http.get(
      `/api/rubricas/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async getRubricasPorConsignatarias(idConsignataria) {
    const { data } = await this._http.get(
      `/api/rubricas/consignataria/${idConsignataria}`,
    )
    return data
  }

  async getRubricaPorNumeroRubrica(dados) {
    if (dados.filters.numeroRubrica.value) {
      const { data } = await this._http.get(
        `/api/rubricas/rubrica/${dados.filters.numeroRubrica.value}`,
      )
      return data
    } else {
      return null
    }
  }

  async saveRubrica(rubrica) {
    if (rubrica.id) {
      return await this._http.put('/api/rubricas', rubrica)
    } else {
      return await this._http.post('/api/rubricas', rubrica)
    }
  }

  async deleteRubrica(rubrica) {
    await this._http.delete(`/api/rubricas/${rubrica.id}`)
  }

  async getRubrica(id) {
    const { data } = await this._http.get(`/api/rubricas/${id}`)
    return data
  }
}
